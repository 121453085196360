import React from 'react';
import { Container, Title, Text, Image } from '@mantine/core';
import Contact from '../components/Contact';
import underConstructionImage from '../assets/underConstruction.jpg'; 

const Home: React.FC = () => {
  return (
    <>
      <Container style={{ width: '80%', margin: '0 auto', marginTop:'50px', color: 'black', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50vh', fontFamily: 'Roboto, sans-serif' }}>
        <Title order={1} style={{ fontSize: '4rem', fontWeight: 'bold' }}>Coming Soon</Title>
        <Image src={underConstructionImage} alt="Under Construction" style={{ margin: '10px auto', maxWidth: '15%', height: 'auto' }} />
        <Text style={{ fontSize: '2rem', fontWeight: 'normal' }}>Website Construction In Progress</Text>
        <Text style={{ margin: '10px auto', fontSize: '1rem', fontWeight: 'normal' }}>For any queries, please find contact details below</Text>
      </Container>
      <Contact />
    </>
  );
};

export default Home;