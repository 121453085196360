import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/logo.jpg';
import classes from './Header.module.css';

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" className={classes.header} style={{ backgroundColor: '#fff', color: '#000' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.inner} style={{ fontFamily: 'Roboto, sans-serif' }}>
          <img src={logo} alt="Company Logo" className={classes.logo} />
          {!isMobile && (
            <div className={classes.desktopMenu}>
              <Typography variant="h6" component="a" href="/" className={classes.link}>
                Home
              </Typography>
              <Typography variant="h6" component="a" className={classes.link}>
                Trading
              </Typography>
              <Typography variant="h6" component="a" className={classes.link}>
                Contact Us
              </Typography>
            </div>
          )}
          {isMobile && (
            <>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen} className={classes.mobileMenuButton}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                className={classes.mobileMenu}
              >
                <MenuItem onClick={handleMenuClose} component="a" href="/">Home</MenuItem>
                <MenuItem onClick={handleMenuClose} component="a" href="/trading">Trading</MenuItem>
                <MenuItem onClick={handleMenuClose} component="a" href="/contact">Contact Us</MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;