import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Switch } from '@mui/material';

const Contact: React.FC = () => {
  const [showAustralia, setShowAustralia] = useState(true); // Toggle state

  const handleToggle = () => {
    setShowAustralia((prev) => !prev); // Toggle between true and false
  };

  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="md" style={{ width: '100%', fontFamily: 'Roboto, sans-serif' }}>
        {/* Toggle Switch with Labels */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography variant="subtitle1" align="center" style={{ marginRight: '10px' }}>
            Contact Details - Australia
          </Typography>
          <Switch
            checked={!showAustralia}
            onChange={handleToggle}
            defaultChecked color='default'
          />
          <Typography variant="subtitle1" align="center" style={{ marginLeft: '10px' }}>
            Contact Details - China
          </Typography>
        </Box>

        <Grid container justifyContent="center">
          {showAustralia ? (
            // Australia Contact Details
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Donghua International Trading Pty Ltd
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.primary" component="p">
                Menghua Zou
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.primary" component="p">
                Chairman 
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                Address: Unit 3, 3273 Logan Road,<br />
                Underwood, QLD 4119
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                Contact Number: (61) 0474 320 181
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                Email: menghua@dhitrading.com.au
              </Typography>
            </Grid>
          ) : (
            // China Contact Details
            <Grid item xs={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Donghua International Trading Pty Ltd
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.primary" component="p">
                Menghua Zou
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.primary" component="p">
                Chairman 
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                Address: 9F, 9#, Lane 803, Shuangcheng Road, <br />
                Baoshan District, Shanghai, China
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                Contact Number: (86) 13636655229
              </Typography>
              <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
                Email: menghua@dhitrading.com.au
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
